import React, { useState, useEffect, useRef } from 'react';
import TipCalculator from './components/TipCalculator';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

// Sample component pages
const AboutUs = () => {
  return (
    <div className="about-us container mx-auto px-4 py-8">
      <h2 className="text-3xl font-bold mb-4">About Us</h2>
      <p className="text-lg mb-6">
        Welcome to Tip Calculator, your go-to app for quick and easy tip calculations! 
        We created this app to help people with their everyday tipping decisions. 
        Whether you're dining out, traveling, or just want to make sure you're being fair 
        to your service provider, our app makes the math easy and fast.
      </p>
      <h3 className="text-2xl font-semibold mb-3">Our Mission</h3>
      <p className="mb-4">
        Our mission is to provide a user-friendly tool that simplifies tipping. We believe 
        in fair compensation for services and hope our app helps users feel confident in 
        their gratuity decisions.
      </p>
      <h3 className="text-2xl font-semibold mb-3">Why Choose Us?</h3>
      <ul className="list-disc pl-5 mb-6">
        <li>Simple and intuitive interface</li>
        <li>Quick calculations for various tip percentages</li>
        <li>Accessible on all devices</li>
        <li>Free to use, with no hidden charges</li>
      </ul>
      <h3 className="text-2xl font-semibold mb-3">Contact Information</h3>
      <p>
        If you have any questions or feedback, feel free to reach out to us at 
        <a href="mailto:contact@tipcalculator.com" className="text-green-600 underline"> contact@tipcalculator.com</a>.
      </p>
    </div>
  );
};

const ContactUs = () => <div><h2>Contact Us</h2><p>This is the Contact Us page.</p></div>;
const BusinessOption1 = () => <div><h2>Option 1</h2><p>This is Business Option 1.</p></div>;
const BusinessOption2 = () => <div><h2>Option 2</h2><p>This is Business Option 2.</p></div>;

function App() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null); // Ref to detect outside clicks

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false); // Close the dropdown when clicking outside
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside the dropdown
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Router>
      <div className="App bg-red-100 min-h-screen flex flex-col">
        <header className="sticky top-0 bg-red-100 shadow-lg z-10 mb-8 h-14 flex items-center justify-center">
          <h1 className="text-4xl font-bold text-center text-green-600">
            {/* Tip Calculator */}
          </h1>
          {/* Navigation Links */}
          <nav className="text-center">
            <ul className="flex justify-center space-x-4">
              <li><Link to="/" className=" font-bold text-black-600 hover:underline">Home</Link></li>
              <li><Link to="/about-us" className=" font-bold text-black-600 hover:underline">About Us</Link></li>

              {/* Dropdown for Business Options */}
              <li className="relative" ref={dropdownRef}>
                <button
                  className="text-black-600 font-bold hover:underline"
                  onClick={toggleDropdown}
                >
                  Business
                </button>
                {dropdownOpen && (
                  <ul className="absolute left-0 mt-2 w-48 bg-white border border-gray-200 shadow-lg">
                    <li className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                      <Link to="/business-option-1">Business Option 1</Link>
                    </li>
                    <li className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                      <Link to="/business-option-2">Business Option 2</Link>
                    </li>
                  </ul>
                )}
              </li>
              <li><Link to="/contact-us" className=" font-bold text-black-600 hover:underline">Contact Us</Link></li>

            </ul>
          </nav>
        </header>

        <main className="flex-grow">
          <div className="container mx-auto px-4">
            <Routes>
              <Route path="/" element={<TipCalculator />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/business-option-1" element={<BusinessOption1 />} />
              <Route path="/business-option-2" element={<BusinessOption2 />} />
            </Routes>
          </div>
        </main>

        <footer className="mt-6 bg-red-100 py-4">
          <p className="text-center text-sm text-gray-500">
            &copy; 2024 Tip Calculator. All rights reserved.
          </p>
        </footer>
      </div>
    </Router>
  );
}

export default App;
